<template>
  <div class="wrap">
    <header-nav></header-nav>
    <div class="discount">
      <div class="discount-in">
        <div class="discount-title">
          <div class="primary-title">[ 精 挑 细 选 ]</div>
          <div class="sub-title">- 高颜值 · 高品质 · 低价格 -</div>
        </div>
        <b-container>
          <b-row class="b-row">
            <b-col class="b-col" sm="6" md="6" lg="4" xl="4" v-for="(item, index) in discount" :key="index">
              <div class="item-wrap">
                <div class="pic">
                  <img :src="item.file_path" :alt="item.goods_name">
                </div>
                <div class="detail">
                  <div class="name" :title="item.style_name + item.goods_name">{{item.style_name}} {{item.goods_name}}</div>
                  <div class="original-price">
                    <div class="price">
                      e家价: ¥<del>{{item.sale_price}}</del>
                    </div>
                    <div class="sales-volume">剩余{{item.sale_count}}件</div>
                  </div>
                  <div class="discount-price">
                    <div class="price">特惠价: <span>¥{{parseInt(item.sale_price*0.75).toFixed(2)}}</span></div>
                    <el-button size="mini" type="info" @click="toProductDetail(item)">立即抢购</el-button>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import Right from "@/components/Right.vue";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
  export default {
    components: {
      headerNav,
      Right,
      footerNav
    },
    data(){
      return {
        token: null,
        discount: []
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getDiscount();
      },
      // 获取活动商品
      getDiscount(){
        this.$api.discount({
          discount_line: 1,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.discount = res.data.goods;
          }
        }).catch(err => console.error(err))
      },
      // 跳转到商品详情
      toProductDetail(obj){
        dump.link({
          type: 3,
          link: "/product/details",
          params: {
            good_id: obj.goods_id
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/view/mall/discount/discount";
</style>
